ul#portfolio.detail { 
	list-style:none; margin:0; /*padding-top:20px; */
}
#portfolio.detail li { 
	/*margin-bottom:20px; position: relative;*/
	border: 10px solid #fff;
	float: left;
	margin: 44px;
	background: #fff;
	text-align: center;
	position: relative;
	overflow: hidden;
  cursor: default;
}  
/*#portfolio.detail li.hentry { padding:0 }*/
/*#portfolio.detail li.first { clear:both }*/


.portfolio.detail h5 a{font-size:16px;}

#portfolio h3{
	margin-top: 20px;
	margin-bottom: 0px;
}

#portfolio.detail li p { margin-bottom:15px; font-size:1em } 
#portfolio.detail li p img { display:block }   

.skylight-dialog img{
	padding-bottom: 20px;
}
.img-zoom{
	width: 10%;
	display: inline-table;
}

ul.filters {/*float:right;*/list-style:none; margin-top: 30px;
	width: 100%;}
ul.filters li {float:left; padding-left: 30px; }
ul.filters li a { /*padding-left: 10px;*/ font-size: 14px; color: #030303; outline: none }
ul.filters li a:hover, ul.filters li a.active { color: #B77A2B }

.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
     -moz-transition-duration: 0.8s;
      -ms-transition-duration: 0.8s;
       -o-transition-duration: 0.8s;
          transition-duration: 0.8s;
}

 

.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:         top, left, opacity;
          transition-property:         transform, opacity;
}
.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

#portfolio.detail .ch-item {
	width: 258px;
	height: 170px;
	position: relative;
	cursor: default;
	border-radius: 0;
	box-shadow: none;
	border: 6px solid #eee;
		
	-webkit-transition: all 0.4s ease-in-out;
	   -moz-transition: all 0.4s ease-in-out;
	     -o-transition: all 0.4s ease-in-out;
	    -ms-transition: all 0.4s ease-in-out;
	        transition: all 0.4s ease-in-out;
}

#portfolio.detail .ch-info {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 0;
	opacity: 0;
    filter: alpha(opacity=0);
	cursor: pointer;
	margin-top: 19%;
	
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	
	-webkit-backface-visibility: hidden; /*for a smooth font */

}
#ie8 #portfolio.detail .ch-item {height: 140px !important; width: 208px !important; }
#ie8 #portfolio.detail .ch-info { background-color: rgb( 0, 0, 0 ); }

#portfolio.detail .ch-info p {
	color: #fff;
	position: relative;
	font-family: 'Open Sans',sans-serif;
	padding: 0;
	margin: 0;
	text-shadow: 
		0 0 1px #fff, 
		0 1px 2px rgba(0,0,0,0.3);
    filter: alpha(opacity=0);
}

#portfolio.detail .ch-info p.title { font-size: 16px; margin-bottom: 7px; text-align:center;}
#portfolio.detail .ch-info p.subtitle { font-size: 11px; text-align:center;}


#portfolio.detail .ch-item-hover:hover, #portfolio.detail .ch-item-opened {
	box-shadow: 
		inset 0 0 0 110px rgba(0,0,0, 0.5),
		0 1px 2px rgba(0,0,0,0.1);
	border: 6px solid #cccccc;
}

#portfolio.detail .ch-item:hover .ch-info, #portfolio.detail .ch-item-opened .ch-info {
	opacity: 1;
    filter: alpha(opacity=70);
	
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);	
}

#ie8 #portfolio.detail .ch-item:hover .ch-info p, #portfolio.detail .ch-item-opened .ch-info p { filter: alpha(opacity=100); }

/*#portfolio.detail .slide_detail {
    cursor: default;
    display: none;
    float: left;
    left: 0;
    margin: 20px 0;
    padding: 15px 0 0 0;
    position: absolute;
    z-index: 9;
    background: #f1f4f5 url('../../img/portfolios/slide_detail_top.png') no-repeat top center;
}*/

#portfolio.detail .portfolio-full-description .work-thumbnail .thumb-wrapper { border:8px solid #cdcfd0; }


.boxed #portfolio.detail .slide_detail {
    padding-left:20px;
    padding-right:20px;
}

/*#portfolio.detail .slide_detail .container {
	background: url('../../img/portfolios/slide_detail_bottom.png') no-repeat bottom center;
	position: relative;
}*/

#portfolio.detail .slide_detail .slide_close {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	z-index: 10;
}

#portfolio.detail .portfolio-full-description li { margin-bottom: 0 }
/*#portfolio.detail .portfolio-full-description .work-skillsdate p { border:0px; margin: 0; background-position:left center; padding-left:30px; }*/
#portfolio.detail .portfolio-full-description .work { margin-bottom: 30px }
#portfolio.detail hr {
    background: none repeat scroll 0 50% transparent;
    border: 0 none;
    color: transparent;
    display: none;
    float: left;
    height: 425px;
    line-height: 0;
    margin: 10px 0;
    position: relative;
    width: 960px;
    z-index: 1;
}







#portfolio.detail .ch-info-icons {
	text-align: center;
	/*margin-top: 19.5%;*/
	margin-bottom: 8%;
}

#portfolio.detail .ch-info-icons svg:hover{
	fill: #a3a3a3;
}

#portfolio.detail .ch-info-text {
	margin: 0;
	padding: 0;
}

/*#portfolio.detail .ch-info-text-noicons {
	margin-top: 40%;
}*/

@media (min-width: 1200px) { 
	#portfolio.detail .ch-item {height: 170px !important; width: 258px !important; }
	.skylight-dialog img {width: 400px;}
}
@media (min-width: 980px) and (max-width: 1199px) {
	#portfolio.detail .ch-item {height: 140px !important; width: 208px !important; }
	.skylight-dialog img {width: 300px;}
}  
@media (min-width: 768px) and (max-width: 979px) {
	#portfolio.detail .ch-item {height: 104px !important; width: 154px !important; }
	
	#portfolio.detail .ch-info-icons { margin-top: 11.5% }
	#portfolio.detail .ch-info p.title { font-size: 13px; margin-bottom: 3px;}
	#portfolio.detail .ch-info p.subtitle { font-size: 10px; }

	#portfolio.detail hr { width: 700px; }
	.skylight-dialog img {width: 250px;}

}
@media (max-width: 767px) {
	#portfolio.detail .ch-item {height: 170px !important; width: 258px !important; }
	.skylight-dialog img {width: 200px;}
}
@media (max-width: 480px) {  }
@media (max-width: 320px) {  }

#portfolio.detail .portfolio-full-description h3 { margin-right: 65px; }















