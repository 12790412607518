$primary-color: #533B66;
$basic-color: #8a8686;
$h1-size: 60px;
$h2-size: 40px;
$h3-size: 27px;
$h4-size: 16px;
$h5-size: 12px;

* {
	font-size: 100.01%;
}
html {
	overflow: scroll;
	-ms-overflow-y: scroll;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	overflow-x: hidden;
}
::-webkit-scrollbar {
	width: 0px;  /* remove scrollbar space */
	background: transparent;  /* optional: just make scrollbar invisible */
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  position: relative;
}
body .inner-wrap h1{
  font-family: Roboto;
  font-size: 77px;
  line-height: 77px;
  font-weight: 100;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #fff;
}
body .inner-wrap span.subheader{
  font-family: Roboto;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
  color: #fff;
  display: block;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
	color: $primary-color;
}
h1{
	font-size: $h1-size;
}
h2{
	font-size: $h2-size;
}
h3{
	font-size: $h3-size;
}
h4{
	font-size: $h4-size;
}
h5{
  text-transform: uppercase;
  font-family: Roboto;
  letter-spacing: 4px;
  font-size: $h5-size;
  line-height: 26px;
  font-weight: 400;
}
a{
	color: $primary-color;
	font-weight: bold;
}
a:hover{
	color: rgba(83, 59, 102, 0.7);
}
p {
	font-family: "Source Sans Pro", sans-serif;
	color: $basic-color;
}


#wrapper{
  width: 100%;
  height: 100%;
  display: table;
  overflow: hidden;
  table-layout: fixed;
}
#header{
  width: 100%;
  top:0;
  left: 0;
  position: fixed;
  padding: 31px 0 32px;
	z-index: 99;
	.header-container{
		padding-left: 15px;
		padding-right: 15px;
		margin-left: auto;
		margin-right: auto;
		width: 85%;
	}
	transition: transform 0.3s ease 0s, background-color 0.3s ease 0s, opacity 0.3s ease 0s, box-shadow 0.3s ease 0s, border-color 0.3s ease 0s, margin 0.25s ease-out 0s;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
}
#header:after{
	content: ' ';
	display: table;
}
.header.fixed{
	background: #fff;
	transition: transform 0.3s ease 0s, background-color 0.3s ease 0s, opacity 0.3s ease 0s, box-shadow 0.3s ease 0s, border-color 0.3s ease 0s, margin 0.25s ease-out 0s;
	border-bottom: 2px solid rgba(0, 0, 0, 0.8) !important;

	#nav li a{
		color: $primary-color;

		&:after{
			display: block;
			content: "";
			background-color: $primary-color;
			height: 2px;
			width: 0%;
			left: 50%;
			position: absolute;
			-webkit-transition: width .3s ease-in-out;
			-moz--transition: width .3s ease-in-out;
			transition: width .3s ease-in-out;
			-webkit-transform: translateX(-50%);
			-moz-transform: translateX(-50%);
			transform: translateX(-50%);
		}
		&:hover:after, &:focus:after{
			width: 100%;
		}
		&.active{
			/*text-decoration: underline;
			-webkit-text-decoration-line: underline;
				-moz-text-decoration-line: underline;*/
				text-decoration: none;
				border-bottom: 2px solid $primary-color;
		}
	}
	.logo a .logo-bg{
		height: 50px;
	width: 266px;
		background: url('../../img/logo_indigodev2.png') 100% 100% no-repeat;
		background-size: contain;
	}
	.nav-opener:before, .nav-opener:after, .nav-opener span {
		background: rgba(83, 59, 102, 1);
	}
}
.container{
  /*margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;*/
  width: 1170px;
}
.logo{
  float: left;
	width: 280px;
}
.logo a .logo-bg{
	cursor: pointer;
	height: 50px;
	width: 266px;
	background: url('../../img/logo_indigodev_white.png') 100% 100% no-repeat;
	background-size: contain;
}
.logo img{
  width: 100%;
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*---------------------navigation menu-begin------------------------------*/
#nav{
  overflow: hidden;
  text-align: right;
  font-size: 0;
  letter-spacing: -4px;
  white-space: nowrap;
  .nav-drop {
		overflow: hidden;
	}
	ul {
		padding: 5px 0 5px;
		margin: 0;
	}
	li {
		display: inline-block;
		vertical-align: top;
		font-size: 1.6rem;
		line-height: 24px;
		font-weight: bold;
		letter-spacing: 0;
		white-space: normal;
		margin: 0 0 0 10px;

		a {
			display: inline-block;
			color: #fff;
			line-height: 1;
			cursor: pointer;
			text-decoration: none;
			position: relative;
			padding: 0;
			margin: 7px 10px;

			
			&:after{
				display: block;
				content: "";
				background-color: #fff;
				height: 2px;
				width: 0%;
				left: 50%;
				position: absolute;
				-webkit-transition: width .3s ease-in-out;
				-moz--transition: width .3s ease-in-out;
				transition: width .3s ease-in-out;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				transform: translateX(-50%);
			}
			&:hover:after, &:focus:after{
				width: 100%;
			}
			&.active{
				/*text-decoration-line: underline;
				-webkit-text-decoration-line: underline;
				-moz-text-decoration-line: underline;*/
				text-decoration: none;
				border-bottom: 2px solid $primary-color;
			}
		}
	}
}
.nav-opener{
  display: none;
  float: right;
  width: 31px;
  height: 31px;
  position: relative;
  text-indent: -9999px;
	overflow: hidden;
	cursor: pointer;
}
.nav-opener:before, .nav-opener:after, .nav-opener span {
	background: #fff;
	border-radius: 4px;
	position: absolute;
	top: 17px;
	left: 3px;
	right: 3px;
	height: 3px;
	margin-top: -2px;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.nav-opener:before {
	content: '';
	top: 8px;
	left: 30%;
}
.nav-opener:after {
	content: '';
	top: 26px;
}
.nav-active .nav-opener span {
	opacity: 0;
}
.nav-opener:hover {
	opacity: .9;
}
.nav-active .nav-opener:after, .nav-active .nav-opener:before {
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 16px;
	left: 5%;
	right: 5%;
}
.nav-active .nav-opener:after {
	-moz-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}



/*---------------------navigation menu-end------------------------------*/

.visual {
	min-height: 700px;
	position: relative;
	overflow: hidden;
	text-align: center;
	color: $primary-color;
	font-size: 0;
	letter-spacing: -4px;

	.bg-stretch {
		position: absolute;
		bottom: 0;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	> .container {
		display: inline-block;
		vertical-align: top;
		position: relative;
		z-index: 1;
		font-size: 2.4rem;
		line-height: 1.5;
		letter-spacing: 0;
	}
	h1 {
		margin: 0 0 10px;
		font-size: 70px;
		line-height: 1.12;
		font-weight: bold;
		color: #fff;
		letter-spacing: 4px;
	}
	p {
		margin: 0 0 8px;
		&.tagline {
			line-height: 1.1;
			font-size: 25px;
			letter-spacing: 4px;
		}
	}
	.info {
		display: block;
		font-size: 1.6rem;
		line-height: 1.5;
		color: #494654;
		text-transform: uppercase;
	}
	.text-block {
		padding: 107px 0;
	}
}

#section2 h2:after, #section3 h2:after, #section6  h2:after, #section7  h2:after, #section8  h2:after{
	bottom: 0;
	left: 50%;
	height: 2px;
	content: '';
	width: 100px;
	margin-left: -50px;
	position: absolute;
	background-color: $primary-color;
}
.main {
	padding: 47px 0;

	.content {
		padding: 25px 15px 23px;
		font-size: 1.3rem;
		line-height: 1.615;
		color: #848e97;
	}
	h1{
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;
		line-height: 60px;
	}
	h2{
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;
		line-height: 60px;
		text-align: center;
	}
	h3 {
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;
		line-height: 60px;

		margin: 0 0 46px;
		line-height: 1.285;
		font-weight: 300;
		text-align: center;
	}
	h4 {
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;
		line-height: 60px;

		margin: 0 0 12px;
		line-height: 1.625;
		font-weight: bold;
		text-align: center;

		.style-01 & {
			margin: 0 0 7px;
		}
	}
	p {
		margin: 0 0 23px;
	}
	.date {
		display: block;
		margin: -5px 0 0;
		padding: 0 0 22px;
	}
	.btn-holder {
		padding: 32px 0 0;
	}
}
.text-box {
	text-align: center;
	font-size: 2.1rem;
	line-height: 1.524;
	color: $primary-color;
	padding-bottom: 23px;
	width: 100%;

	h1 {
		margin: 0 0 20px;
		line-height: 1.148;
		text-transform: uppercase;
		font-weight: bold;
	}
	p {
		margin: 0 0 35px;
	}
}
.info {
  display: block;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #494654;
  text-transform: uppercase;
}

.react-parallax {
  position: unset!important;
}
/*---------------------FOOTER-begin------------------------------*/
#footer {
	width: 100%;
	overflow: hidden;
	display: table-footer-group;
	height: 1%;
}
.footer-holder {
	position: relative;
	padding: 62px 15px 0px;
	font-size: 22px;
	line-height: 1.6428571429;
	color: #848e97;

	&:before {
		content: '';
		position: absolute;
		height: 1px;
		top: 0;
		right: 15px;
		left: 15px;
		background: #f4f4f4;
	}
	.logo {
		margin: 0 0 23px;
		padding: 4px 0 0;
		float: none;
		display: block;

		 
	}
	p {
		line-height: 21px;
		font-size: 14px;
	}
	h3 {
		margin: 0 0 14px;
		line-height: 1.5;
		font-weight: bold;
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		font-weight: bold;
		font-size: 1.5rem;
		line-height: 1.55;

		a {
			color: #8d81ac;

			&:hover {
				color: #716298;
			}
		}
	}
	.text-holder {
		padding: 0 0 0 10px;
	}
	.text-frame {
		padding: 0 0 0 25px;
		font-size: 14px;
	}
	.phone {
		display: block;
		margin: 0 0 6px;
		line-height: 1.2;
		a {
			color: #31384d;
		}
	}
	.available {
		display: block;
		padding: 0 0 29px;
		font-size: 14px;
		line-height: 1.3333333;
		font-weight: bold;
		text-transform: uppercase;
		color: $basic-color;
	}
	address {
		display: block;
		margin: -3px 0 0;
		font-style: normal;
		line-height: 23px;
	}
	.button_social_group{
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;
	}
	#social-in-menu a{
		display: inline-block;
		margin-right: 0;
		margin-left: 0;
		overflow: hidden;
	}
	#social-in-menu i{
		/*height:26px;*/ 
		font-size:18px!important; 
		margin-right:10px; 
		width:30px; 
		line-height:28px
	}
	[class*="fa-"]
	{
		display:inline-block;  
		font-size:16px;  
		width:32px;  
		height:32px;  
		line-height:32px;  
		top:-2px;  
		word-spacing:1px;  
		position:relative;  
		text-align:center;  
		vertical-align:middle;  
		max-width:100%
	}
	 #social-in-menu i
	 {
		 font-size:16px!important; 
		 color:inherit!important; 
		 background-color:transparent; 
		 transition:transform 0.25s ease, opacity 0.2s linear; 
		 -webkit-transition:-webkit-transform 0.25s ease,opacity 0.2s linear; 
		 text-align:center; 
		 line-height:24px; 
		 height:34px; 
		 width:34px}

body[data-header-search="false"]:not(.mobile) #header-outer[data-has-menu="false"][data-cart="false"] #social-in-menu i{font-size:20px!important; width:38px; line-height:26px; height:26px; margin-bottom:-3px}

 #social-in-menu a
 {
	 display:inline-block; 
	 margin-right:0; 
	 margin-left:0; 
	 overflow:hidden;
	 color: #000000!important;
	}
	#social-in-menu a:hover
 {
	
	 color: #490d80!important;
	}

 #social-in-menu a:hover i{-webkit-transform:translateY(-100%);  -moz-transform:translateY(-100%);  transform:translateY(-100%)}

 #social-in-menu a i:after{ position:absolute;  top:100%;  left:0;  -webkit-transform:translate3d(0,0,0);  -moz-transform:translate3d(0,0,0);  transform:translate3d(0,0,0);  width:100%}
 #social-in-menu a i:before{width:100%}

#social-in-menu [class^="fa-"]:after, #header-outer #social-in-menu [class*="fa-"]:after, #header-outer #social-in-menu .fa-vine:before, #header-outer #social-in-menu .fa-vine:after{text-decoration:inherit;  display:block; }
#social-in-menu .fa-vine{display:block; text-align:center}
#social-in-menu i.fa-facebook, i.fa-twitter, i.fa-linkedin, i.fa-google-plus, i.fa-instagram
{
	width:34px!important; 
	height: 34px!important;
}
#social-in-menu .fa-instagram:after{content:"\f16d"}
#social-in-menu .fa-vk:after{content:"\f189"}
#social-in-menu .fa-odnoklassniki-square:after{content:"\f264"}
#social-in-menu .fa-odnoklassniki:after{content:"\f263"}
#social-in-menu .icon-salient-spotify:after{content:"\f1bc"}
#social-in-menu .fa-google-plus:after{content:"\f0d5"}
#social-in-menu .fa-linkedin:after{content:"\f0e1"}
#social-in-menu .fa-facebook:after{content:"\f09a"}
#social-in-menu .fa-twitter:after{content:"\f099"}

}
/*---------------------FOOTER-end------------------------------*/

/*---------------------team-members-begin------------------------------*/

.col-md-3{
	float: left;
}
.team-row{
	width: 100%;
	height: 380px;
}

.view{
	border: 10px solid #fff;
	float: left;
	margin: 52px;
	background: #fff;
	text-align: center;
	position: relative;
	overflow: hidden;
  cursor: default;
}
.view .mask,
.view .content {
  width: 255px;
  height: 339px;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.view img {
  transition: all 0.2s linear!important;
  width: 255px;
	height: 339px;
	display: block;
	position: relative;
}
.view h2 {
  color: #fff;
  text-align: center;
  position: relative;
  padding: 10px;
	line-height: 48px;
	margin-bottom: 0px;
}

.view p {
  
  font-style: italic;
  font-size: 14px;
  position: relative;
  color: #fff;
  padding: 0px 20px 0px;
  text-align: center
}
.view .mask {
  opacity: 0;
  background-color: rgba(83, 59, 102, 0.7);
  transition: all 0.4s ease-in-out;
}
.view h4 {
  transform: translateY(-100px);
  opacity: 0;
	transition: all 0.2s ease-in-out;
	color: #fff;
	margin-bottom: 0;
	padding-bottom: 0;
}
.view h5{
	color: #fff;
}
.view p {
  transform: translateY(100px);
  opacity: 0;
	transition: all 0.2s linear;
}
.view:hover img, .view:focus img {
  transform: scale(1.1);
}

.view:hover .mask, .view:focus .mask {
  opacity: 1;
}

.view:hover h1,
.view:hover p, .view:focus h1,
.view:focus p {
  opacity: 1;
  transform: translateY(0px);
}

.view:hover p, .view:focus p {
  transition-delay: 0.1s;
}
/*---------------------team-members-end------------------------------*/
.custom-bg{
	background-position: center center;
	background-repeat: no-repeat;
	img{
		width: 100%;
	}
}
.hero-container {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.hero-image {
	width: 100%;
	height: 100vh; // this should be dynamic, right now it's fixed based on a min max offset
	background-size: cover;
	background-position: center;
}

.hero-children {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	
	display: flex;
	align-items: center;
	justify-content: center;
}


/*---------------------services-begin------------------------------*/
.feature-box {
	width: 100%;
	overflow: hidden;
	text-align: left;
	margin: 0 auto 40px;
}
.feature-box .box-icon {
	float: left;
	width: 90px;
	height: 90px;
	color: #ffffff;
	font-size: 45px;
	overflow: hidden;
	text-align: center;
	display: inline-block;
	margin: 0 20px 15px 0;
	background-color: $primary-color;

	-webkit-border-radius: 50%;
		 -moz-border-radius: 50%;
			 -o-border-radius: 50%;
					border-radius: 50%;

	-webkit-transition: all 0.3s ease-in-out;
		 -moz-transition: all 0.3s ease-in-out;
			-ms-transition: all 0.3s ease-in-out;
			 -o-transition: all 0.3s ease-in-out;
					transition: all 0.3s ease-in-out;
}
.feature-box .box-icon i { line-height: 91px; }
.box-content { overflow: hidden; }
.feature-box .box-title {
	width: 100%;
	overflow: hidden;
	margin: 0 auto 15px;
	text-transform: capitalize;
}
.feature-box .box-title h3{
	margin: 0;
	text-align: left;
}
.box-desc {
	width: 100%;
	overflow: hidden;
}
.feature-box:hover .box-icon { background-color: rgba(83, 59, 102, 0.7); }
/*---------------------services-end------------------------------*/

/*---------------------skills-begin------------------------------*/
#section3{
	width: 100%;

    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center center;
		background-image: url('../../img/Belyj-fon.jpg');

    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
						background-size: cover;
		
		 color: #fff;
}

.skills{
	width: 100%;
	.col-md-3{
		margin-top: 50px;
	}
}
.skills .box-icon{
	color: $primary-color;
	font-size: 55px;
	margin-bottom: 35px;
	text-align: center;
}
/*---------------------skills-end------------------------------*/

/*---------------------technologies-begin----------------------------*/

.technologies{
	display: -ms-flexbox;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	div{
		width: 23.2%;
		margin-bottom: 5%;	
		margin-top: 3%;
		text-align: center;
	}
}

.technologies img{
	max-width: 100%;
	max-height: 70px;

}
/*---------------------technologies-end------------------------------*/


/*---------------------contact-form-begin------------------------------*/
#section6 {
	width: 100%;
	overflow: hidden;
	position: relative;
	padding: 100px 0 45px;
	background-color: #ffffff;
}
#contactform .form-control {
	width: 100%;
	height: 50px;
	outline: none;
	color: $primary-color;
	padding: 6px 16px;
	margin-bottom: 30px;
	background-color: #ffffff;

	-webkit-box-shadow: none;
		 -moz-box-shadow: none;
			-ms-box-shadow: none;
			 -o-box-shadow: none;
					box-shadow: none;

	-webkit-appearance: none;
	 -moz-appearance: none;
			-ms-appearance: none;
			 -o-appearance: none;
					appearance: none;

	-webkit-border-radius: 12px;
		 -moz-border-radius: 12px;
			 -o-border-radius: 12px;
					border-radius: 12px;

	-webkit-border: 2px solid $primary-color;
		 -moz-border: 2px solid $primary-color;
			 -o-border: 2px solid $primary-color;
					border: 2px solid $primary-color;
}
#contactform .form-control:hover,
#contactform .form-control:focus {
	-webkit-border-color: rgba(83, 59, 102, 0.5);
		 -moz-border-color: rgba(83, 59, 102, 0.5);
			 -o-border-color: rgba(83, 59, 102, 0.5);
					border-color: rgba(83, 59, 102, 0.5);
}

.form-group {
	margin: 0;
	padding: 0;
	overflow: hidden;
	position: relative;
}
.col-md-4, .col-md-12{
	float: left;
}

#contactform textarea {
	width: 100%;
    resize: none;
    margin: 0 auto;
    height: 160px !important;
    padding: 15px 12px !important;
}
#contactform button {
	outline: none;
	display: block;
	margin: 0 auto;
}
.form-message
	{
		
		border: 2px solid #e14d43;
		border-radius: 12px;
		margin-bottom: 20px;
	
}
.form-message li{
	width: 100%;
	color: #e14d43;
	min-height: 28px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 6px 16px;
}

.form-error{
	.form-control{
	-webkit-border-color: #e14d43!important;
		 -moz-border-color: #e14d43!important;
			 -o-border-color: #e14d43!important;
					border-color: #e14d43!important;
	}
}


.btn-nesto,
.btn.btn-nesto {
    z-index: 1;
    outline: none;
    margin: 0 5px;
    color: #ffffff;
    font-weight: 300;
    overflow: hidden;
    position: relative;
    letter-spacing: 1px;
    display: inline-block;
    text-transform: uppercase;
    background-color: $primary-color;
    font-family: 'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;

    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
         -o-border-radius: 10px;
            border-radius: 10px;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

    -webkit-border: 2px solid transparent;
       -moz-border: 2px solid transparent;
         -o-border: 2px solid transparent;
            border: 2px solid transparent;

    -webkit-transition: all 0.3s ease-in-out;
       -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
         -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
}
.btn-nesto:hover { background-color: rgba(83, 59, 102, 0.5); }

/*alert begin*/
.s-alert-box{
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif!important;
	border: 1px solid!important;
	margin: 10px 0px!important;
	padding:15px 10px 15px 50px!important;
	
}
.s-alert-success{
	color: #4F8A10!important;
	background-color: #DFF2BF!important;
	background-image: url('../../img/success.png')!important;
	background-repeat: no-repeat!important;
	background-position: 10px center!important;

	.s-alert-close:before, .s-alert-close:after{
		background: #4F8A10!important;
	}
}
.s-alert-error{
	color: #D8000C!important;
	background-color: #FFBABA!important;
	background-image: url('../../img/error.png')!important;
	background-repeat: no-repeat!important;
	background-position: 10px center!important;

	.s-alert-close:before, .s-alert-close:after{
		background: #D8000C!important;
	}
}
/*alert end*/


/*---------------------contact-form-end------------------------------*/


/*---------------------testimonials-slider-begin---------------------*/
#section4{
	width: 100%;
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center center;
		background-image: url('../../img/purple-8.jpg');

    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
						background-size: cover;
		
		 color: #fff;
		 z-index: 1;
		 h2{
			 color: #fff;
			 text-transform: uppercase;
		 }
}
.slider-wrapper {
  position: relative;
	height: 360px;
	overflow: hidden;
	margin-top: 40px;
}



.slide {
	height: 360px;
  background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
}

.previousButton, .nextButton {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		//background: url('../../img/41_.svg') no-repeat center center / 16px;
		width: 32px;
		height: 32px;
		//text-indent: -9999px;
		cursor: pointer;
		color:#a3a3a3!important;
		//font-family: 'Glyphicons Halflings';
		polygon{
			fill: $basic-color;
		}
}
/*.previousButton:before {
	content: '\003C';
 }
	
.nextButton:before {
	content: '\003E';
}

	.previousButton:hover {
		color: #5b5b5a!important; 
	}
		
	.nextButton:hover {
		color: #5b5b5a!important; 
	}*/

/*.previousButton:hover, .nextButton:hover {
  background: url('../../img//41_2.svg') no-repeat center center / 16px;
}*/

.previousButton {
  left: 0;
 /* -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
					transform: rotate(180deg) translateY(calc(50% + 0px));*/
					polygon{
						transform: rotate(0 10 15);
					}
}

.previousButton:hover {
	left: 0px;
	polygon{
		fill: #5b5b5a;
	}
}

.nextButton {
  right: 0;
}

.nextButton:hover {
	right: 0px;
	polygon{
		fill: #5b5b5a;
	}
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slider-content .inner button{
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: $primary-color;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
	color: #fff;
  font-size: 16px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 740px;
}

.slider-content section {
	display: block;
	padding: 0 0 40px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  font-size: 13px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
	margin-left: 10px;
}

.slider-content section img {
  width: 85px;
  height: 85px;
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
	color: #fff;
  font-size: 24px;
  display: block;
}


/*---------------------testimonials-slider-aimations-begin-------------*/

.slide h1 {
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide button {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide p {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide section * {
	transition: all 0.3s ease;
}

.slide section img {
	-webkit-transform: translateX(-10px);
					transform: translateX(-10px);
	opacity: 0;
}

.slide section span {
	-webkit-transform: translateY(-10px);
					transform: translateY(-10px);
	opacity: 0;
}

.slide section span strong {
	-webkit-transform: translateY(10px);
					transform: translateY(10px);
	opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: .9s;
					transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	-webkit-transition-delay: 1.1s;
					transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	-webkit-transition-delay: 1.3s;
					transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
	-webkit-transition-delay: 1.3s;
					transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
	-webkit-transition-delay: 1.4s;
					transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
	-webkit-transition-delay: 1.5s;
					transition-delay: 1.5s;
}

.slide.animateOut h1 {
	-webkit-transition-delay: .3s;
					transition-delay: .3s;
}

.slide.animateOut p {
	-webkit-transition-delay: .2s;
					transition-delay: .2s;
}

.slide.animateOut section span {
	-webkit-transition-delay: .1s;
					transition-delay: .1s;
}

.slide.animateOut section span strong {
	-webkit-transition-delay: 0s;
					transition-delay: 0s;
}

/*---------------------testimonials-slider-aimations-end--------------*/
/*---------------------testimonials-slider-end-----------------------*/

/*---------------------media begin---------------------------------*/
@media only screen and (max-width:770px) {
	.slider-wrapper, .slide {
    height: 460px;
	}  
	.view{
		margin: -3px!important;
	} 
	.visual{
		min-height: 470px;
	} 
	.visual .text-block{
		padding: 40px 0;
	}
	.visual h1{
		font-size: 40px;
	}
	.visual p.tagline{
		font-size: 16px;
	}
	.footer-holder {
		.logo{
			width: 100%;
		}
		.phone a{
			font-size: 14px;
		}
		h4, h3{
			font-size: 14px;
		}
		p{
			font-size: 12px;
		}
	}
	.col-md-3{
		width: 25%;
	}
}
@media only screen and (min-width:770px) and (max-width:990px) {
	.visual h1{
		font-size: 60px;
	}
	.view{
		margin: 42px!important;
	}
	.footer-holder {
		.logo{
			width: 100%;
		}
		.phone a{
			font-size: 15px;
		}
		h4, h3{
			font-size: 15px;
		}
		p{
			font-size: 14px;
		}
	}
}
@media only screen and (min-width:990px) and (max-width:1200px) {
	.view{
		margin: 20px!important;
	}
}
@media only screen and (max-width:1235px) {
	.nav-opener {
		display: inline-block;
		vertical-align: top;
	}
	.opener-holder {
		float: right;
		display: inline-block;
		vertical-align: top;
		position: relative;
		padding: 0 0 0 25px;
		margin: 7px 25px 0;

		&:before {
			position: absolute;
			content: "";
			width: 1px;
			left: 0;
			top: 0;
			bottom: -6px;
			background: #f4f4f4;
		}
	}
	
	#nav {
		overflow: visible;
		font-size: 1.8rem;
		line-height: 1.3333333;
		letter-spacing: 0;
		font-weight: normal;

		.nav-drop {
			display: block;
			clear: both;
			width: 100%;
			max-width: 400px;
			position: absolute;
			right: 0;
			top: 100%;
			pointer-events: none;
			opacity: 0;
			-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
			transition: transform 0.3s, opacity 0.3s;
			-webkit-transform: translate3d(0, 30px, 0);
			transform: translate3d(0, 30px, 0);
			> ul {
				display: block;
				padding: 0;
				text-align: left;

				li {
					position: relative;
					margin: 0;
					padding: 0 35px;
					display: block;
					font-size: 1.8rem;
					line-height: 1.3333333;
					font-weight: normal;

					&.active:before {
						content: '';
						position: absolute;
						top: 20px;
						bottom: 20px;
						width: 4px;
						left: 0;
						background: $primary-color;
					}
					a {
						display: block;
						margin: 0 -9999px;
						padding: 20px 9979px;
						border-bottom: 1px solid #3E3D3F;
						color: #fff!important;
						background: rgba(51, 51, 51, 0.98);
						text-decoration: none;

						&:active, &:focus, &:hover {
							text-decoration: none;
							color: $primary-color;
						}
					}
					&.active {
						position: relative;

						a {
							color: #858585;
						}
					}
				}
			}
			
		}
	}

	#nav .nav-active .nav-drop {
		pointer-events: auto;
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);

		li{
			a{
				&.active, &:hover, &:after{
						text-decoration: none;
						content: none;
						color: #fff;
						background: $primary-color;
				}
			}
		}
	}
	
}
/*---------------------media end-------------------------------------*/
